a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
    display: none;
}

html {
    font-size: 14px;
    background: url(../assets/img/Underconstruction.gif) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

@media (min-width: 768px) { 
    html { font-size: 16px; }
}

.box-shadow { box-shadow: 0 .25rem .75rem rgb(255, 106, 0); }

.navbar-expand-sm {
    background-color: #000000;
    height: 5px !important;
}